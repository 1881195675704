import { useState } from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import { PaymentLabsLogo } from "@pl/app-component-lib";
import { ThemeConfig } from "@pl/app-services";
import { Footer } from "../footer/Footer";

export type AuthContainerProps = {
  children: React.ReactNode;
  withFooter?: boolean;
  theme?: ThemeConfig;
};

export function AuthContainer({
  children,
  theme,
  withFooter = true,
}: AuthContainerProps) {
  const [isBannerImageLoaded, setIsBannerImageLoaded] = useState(false);

  function onBannerImageLoaded(): void {
    setIsBannerImageLoaded(true);
  }

  const logoUrl = theme?.loginBannerUrl;

  return (
    <div className="flex h-full flex-col justify-center">
      <div className="mb-16 flex flex-1 items-center justify-center">
        <div className="max-w-screen-sm flex-1">
          <div
            className="bg-bg-container xs:p-14 rounded-xl p-6 shadow-sm"
            role="region"
          >
            <div className="mb-8 flex flex-wrap justify-between gap-y-4">
              {!logoUrl && <PaymentLabsLogo />}
              {logoUrl && (
                <>
                  {!isBannerImageLoaded && (
                    <div className="bg-bg-neutral mb-4 h-10 w-60 animate-pulse" />
                  )}
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={logoUrl}
                    className={cx("h-10 w-60", {
                      hidden: !isBannerImageLoaded,
                    })}
                    alt="logo"
                    onLoad={onBannerImageLoaded}
                  />
                  <div className="flex items-center">
                    <p className="text-label-sm px-2">
                      <FormattedMessage id="login.banner.powered-by" />
                    </p>
                    <PaymentLabsLogo customClasses="h-10 w-32" />
                  </div>
                </>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      {withFooter && <Footer />}
    </div>
  );
}
