import { useEffect } from "react";
import {
  AuthContainer,
  usePaymentLabsRouter,
  useRouterState,
} from "@pl/app-common";
import { RenderStateSection } from "@pl/app-component-lib";
import { getTokenState } from "@pl/app-services";

export default function Page() {
  const routerState = useRouterState();
  const router = usePaymentLabsRouter();

  useEffect(() => {
    if (routerState !== "idle") {
      return;
    }

    const tokenState = getTokenState();
    if (tokenState === "valid") {
      router.goToIndex();
    } else {
      router.goToLogin();
    }
  }, [router, routerState]);

  return (
    <AuthContainer withFooter>
      <RenderStateSection variant="loading" height="xl" />
    </AuthContainer>
  );
}
