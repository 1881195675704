import { useEffect, useState } from "react";
import { useRouter } from "next/router";

/*
 * Inspired by https://github.com/vercel/next.js/issues/39128
 */
export function useRouterState() {
  const [state, setState] = useState<"idle" | "start" | "complete" | "error">(
    "idle"
  );
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => {
      setState("start");
    };
    const handleComplete = () => {
      setState("complete");
    };
    const handleError = () => {
      setState("error");
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleError);
    };
  }, [router]);

  return state;
}
