import NextLink from "next/link";
import cx from "classnames";

export const LinkType = ["primary", "secondary", "inverse"] as const;

export type LinkProps = {
  as: "a" | "next";
  href: string;
  text: string;
  type?: (typeof LinkType)[number];
  target?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export function Link({ text, as, type = "primary", ...props }: LinkProps) {
  const classNames = cx("text-label-sm hover:underline underline-offset-2", {
    "text-int-txt-brand active:text-int-txt-brand-active": type === "primary",
    "text-int-txt-default active:text-int-txt-default-active":
      type === "secondary",
    "text-int-txt-inverse active:text-int-txt-inverse-active":
      type === "inverse",
  });

  if (as === "a") {
    return (
      <a className={classNames} {...props}>
        {text}
      </a>
    );
  } else {
    return (
      <NextLink className={classNames} {...props}>
        {text}
      </NextLink>
    );
  }
}
